<script setup lang="ts">
import { BaseBadge, copyToClipboard } from '@legacy-studio/library';
import TooltipAlert from './TooltipAlert.vue';

withDefaults(
	defineProps<{
		message?: string;
	}>(),
	{
		message: 'Скопировано',
	},
);

defineSlots<{
	default: {
		copy: (value: string) => void;
	};
}>();

const tooltip = ref<InstanceType<typeof TooltipAlert> | null>(null);
function copy(value: string) {
	copyToClipboard(value);
	tooltip.value?.open();
}
</script>

<template>
	<TooltipAlert
		ref="tooltip"
		:title="message"
		:time="1500"
		class="h-fit w-fit"
	>
		<!-- <div
      class="px-2.5 py-0.5 rounded-md bg-gray-600 flex items-center justify-center gap-1"
    >
      <slot :copy="copy" />
    </div> -->

		<BaseBadge color="gray">
			<slot :copy="copy" />
		</BaseBadge>
	</TooltipAlert>
</template>
